import { DicService } from './services/dic.service';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventsService } from './services/events.service';
import { ApiService } from './services/api.service';
import { httpInterceptorProviders } from './interceptors';
import { SharedModule } from './shared/shared.module';
import { ModalService } from './services/modal.service';
import { SPErrorHandlerProviders } from './common/http-error-handlers';
import { PreloaderService } from './services/preloader.service';
import { HostMessageService } from './services/host-message.service';
import { SettingsService } from './services/settings.service';
import { ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment-mini-ts';
import { ProfileService } from './services/profile.service';
import { StorageService } from './services/storage.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule, FirebaseAppConfig } from '@angular/fire';
import { VkAuthWrap } from './shared/classes/vk-auth';
import { DictionaryDataService } from './services/dictionary-data.service';

export const firebaseConfig: FirebaseAppConfig = {
  apiKey: 'AIzaSyCYoa1Jek2C9a38nhOs8goOaGpJlOPgPrs',
  authDomain: 'sport-priority-services.firebaseapp.com',
  databaseURL: 'sport-priority-services.firebaseio.com',
  storageBucket: '',
  messagingSenderId: '',
};

registerLocaleData(localeRu);
moment.locale('ru');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyCYoa1Jek2C9a38nhOs8goOaGpJlOPgPrs',
      authDomain: 'sport-priority-services.firebaseapp.com',
      databaseURL: 'sport-priority-services.firebaseio.com',
      storageBucket: '',
      messagingSenderId: '',
    }),
    AngularFireAuthModule
  ],
  providers: [
    HostMessageService,
    EventsService,
    SettingsService,
    ApiService,
    ModalService,
    PreloaderService,
    ProfileService,
    StorageService,
    httpInterceptorProviders,
    VkAuthWrap,
    SPErrorHandlerProviders,
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    DicService,
    DictionaryDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
