
import {fromEvent as observableFromEvent,  Observable ,  Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';


@Injectable()
export class EventsService {
  public readonly clickObserver = observableFromEvent<Event>(document, 'click');
  public readonly resizeObserver = observableFromEvent<Event>(window, 'resize');
  private events = new Subject<{ subject: string, payload?: any }>();
  private channels: { [key: string]: Subject<any>; } = {};

  // TODO: enum для subject
  public publish(subject: string, options?: { timeout?: number, payload?: any }) {
    if (options && options.timeout) {
      setInterval(() => {
        this.events.next({ subject, payload: options && options.payload || undefined });
      },  options.timeout * 1000);
    } else {
      this.events.next({ subject, payload: options && options.payload || undefined });
    }
  }

  public event(subject: string) {
    return this.events
      .pipe(filter(x => x.subject === subject));
  }

  public on(subject: string, callback: (data?: any) => void) {
    return this.event(subject).subscribe(event => callback(event.payload));
  }

   /**
     * Subscribe to a topic and provide a single handler/observer.
     * @param topic The name of the topic to subscribe to.
     * @param observer The observer or callback function to listen when changes are published.
     *
     * @returns Subscription from which you can unsubscribe to release memory resources and to prevent memory leak.
     */
    subscribe(topic: string, observer: (_: any) => void): Subscription {
      if (!this.channels[topic]) {
          this.channels[topic] = new Subject<any>();
      }

      return this.channels[topic].subscribe(observer);
  }

  /**
     * Publish some data to the subscribers of the given topic.
     * @param topic The name of the topic to emit data to.
     * @param data data in any format to pass on.
     */
    publishNew(topic: string, data: any): void {
      const subject = this.channels[topic];
      if (!subject) {
          // Or you can create a new subject for future subscribers
          return;
      }

      subject.next(data);
  }
}
