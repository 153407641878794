
import { fromEvent as observableFromEvent, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';


import { SettingsService } from './settings.service';
import { EventsService } from './events.service';
import { QueryStringParams, isWParams, isVkParams } from '../shared/models/parameters.model';
import { WidgetSettings, WidgetType } from '../shared/models/widget.model';


declare global {
  interface Window {
    VK?: {
      callMethod: (methodName: string, ...params) => void
    };
  }
}

@Injectable()
export class HostMessageService {
  // Устанавливается в настройках vk при создании приложения.
  private readonly VKIframeWidth = 995;

  private settings: WidgetSettings = null;
  private params: QueryStringParams = null;
  public hostWindow: Window;
  public originUrl: string;
  private _hostClick = new BehaviorSubject<void>(null);
  private posY: number;

  constructor(
    private settingsService: SettingsService,
    private eventsService: EventsService,
  ) {
    this.settingsService.settings
      .subscribe(settings => this.settings = settings);

    this.settingsService.params
      .subscribe(params => {
        if (!this.params) {
          this.params = params;
        }
      });

    observableFromEvent<MessageEvent>(window, 'message')
      .subscribe(event => {
        if (
          event.data.type === 'widget-link' &&
          event.data.id === this.widgetId
        ) {
          this.hostWindow = <any>event.source;
          this.originUrl = event.origin;
          this.postMessage({ type: 'widget-link' });
          this.eventsService.publish('height', { timeout: 1 });
        }
        if (event.data.type === 'host-click') {
          this._hostClick.next(null);
        }
      });
  }

  // TODO: enum для типов сообщений
  public postMessage(message: { [key: string]: any }) {
    if (this.settings && this.settings.widgetType && this.settings.widgetType === WidgetType.Schedule) {

      // Если виджет в приложении
      if (message.height && message.extra) {
        parent.postMessage({ heightWg: message.height, extra: true }, '*');
      }
    }

    if (this.hostWindow) {

      // Виджет запущен на сайте
      const messageWithId = { ...message, widgetId: this.widgetId, widgetType: this.settings?.widgetType };

      // Для виджета расписания при отправке сообщения на установку
      // новой высоты необходимо учитывать свойство extra /2l20lt5c/
      if (
        (messageWithId as any).type === 'widget-set-height' && this.settings
          && (this.settings.widgetType === WidgetType.Schedule || this.settings.widgetType === WidgetType.Certificates)
        ) {
        if (!message.extra) return;

        this.hostWindow.postMessage(messageWithId, '*');
      } else {
        this.hostWindow.postMessage(messageWithId, '*');
      }

    } else if (this.params && isVkParams(this.params) && window.VK) {
      // Виджет запущен в ВК
      if (message.type === 'widget-set-height') {
        window.VK.callMethod('resizeWindow', this.VKIframeWidth, message.height);
      }
      document.body.onclick = (e) => {
        this.posY = e.pageY;
        return this.posY;
      };
      if (message.type === 'widget-modal-open') {
        // FIXME: здесь пока просто прокрутка к началу экрана.
        // Вообще нужно правильно отслеживать позицию прокрутки и возвращаться обратно после закрытия модалки,
        // но для этого надо сначала убедиться в том, что api vk вообще правильно подключено и работает
        // https://vk.com/dev/clientapi?f=9.%20scrollWindow
        window.VK.callMethod('scrollWindow', 0, 0);
      }
      if (message.type === 'widget-modal-close') {
        window.VK.callMethod("scrollWindow", this.posY, 0);
      }
    }
  }

  private get widgetId(): string {
    if (this.params && isWParams(this.params)) {
      return this.params.w;
    } else if (this.settings && this.settings.widgetId) {
      return this.settings.widgetId;
    }
  }

  public get hostClick() {
    return this._hostClick.asObservable();
  }


}
