import { IClubDictionaryData } from './../shared/models/club-dictionary-data.model';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class DictionaryDataService {

  constructor(
    private apiService: ApiService
  ) { }

  getDictionariesByClub(clubId: string) {
    return this.apiService.clubDictionaries.getByClub(clubId);
  }

  saveDictionary(clubDictionaryData: IClubDictionaryData) {
    return this.apiService.clubDictionaries.save(clubDictionaryData);
  }

  removeDictionary(clubDictionaryData: IClubDictionaryData) {
    return this.apiService.clubDictionaries.remove(clubDictionaryData);
  }

  getDictionary(clubId: string, alias: string) {
    return this.apiService.clubDictionaries.get(clubId, alias);
  }

  getDefaultDictionary(clubId: string) {
    return this.apiService.clubDictionaries.getIsDefault(clubId);
  }

  setIsDefaultDictionary(dictionary: IClubDictionaryData) {
    return this.apiService.clubDictionaries.setIsDefault(dictionary);
  }

}
