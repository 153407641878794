import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class PreloaderService {

  private counter: BehaviorSubject<number> = new BehaviorSubject(0);

  public increment() {
    this.counter.next(this.counter.value + 1);
  }

  public decrement() {
    this.counter.next(Math.max(this.counter.value - 1, 0));
  }

  public get isBusy() {
    return this.counter.pipe(
      map(res => res !== 0),
      debounceTime(300),
      distinctUntilChanged()
    );
  }
}
