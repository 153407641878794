import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalReq } from '../shared/models/misc.models';

@Injectable()
export class ModalService {
  private _modalData: BehaviorSubject<ModalReq> = new BehaviorSubject(null);
  public scrollSchedulePositionY: BehaviorSubject<number> = new BehaviorSubject(null);

  public get modalData() {
    return this._modalData.asObservable();
  }

  public show(req: ModalReq) {
    this._modalData.next(req);
  }

  public close() {
    this._modalData.next(null);
    this.skipModal();
  }

  public get isModalVisible() {
    return this._modalData.pipe(
      map(res => res !== null)
    );
  }

  // Баг "Призрачное расписание"
  private skipModal() {
    document.getElementsByClassName("modal")[0].setAttribute("style", "display: none;");
    this.scrollSchedulePositionY.subscribe(positionY => window.scrollTo({ top: positionY }));
  }
}
