export const environment = {
    production: true,
    imageBaseUrl: 'https://media.sportpriority.com/img',
    api: 'https://api.sportpriority.com/v2/wg',
    widgetHost: 'https://wg.sportpriority.com',
    widgetIdInApp: '0cc480b993a1452f962d9652838c8113',
    vkAppIds: {
        rooms: '6445829',
        schedule: '6277910',
        subscriptions: '6445819',
        courses: '6486550',
        coaches: '6486553'
    },
    vkAuthUrl: 'https://api.sportpriority.com/v1/login-vk/',
    siteBaseUrl: 'https://sportpriority.com/',
    offerUrl: 'https://sportpriority.com/offer',
    politicsUrl: 'https://sportpriority.com/politika-obrabotki-personalnyh-dannyh/',
    paymentUrl: 'https://sportpriority.com/pay-widget',
    paymentCertUrl: 'https://sportpriority.com/pay-certwg',
    externalPaymentUrl: 'https://sportpriority.com/pay-app/',
    externalPaymentSbpUrl: 'https://sportpriority.com/pay-app-sbp/'
};


