import { Subject } from 'rxjs';
import { IClubDictionaryData } from './../shared/models/club-dictionary-data.model';
import { DictionaryDataService } from './dictionary-data.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DicService {
  public isLoadDicData = new Subject<boolean>();

  constructor(
    private dictionaryDataService: DictionaryDataService,
  ) { }

  public data: any;

  public async setData(clubId: string, dicAlias: string) {
    (await this.dictionaryDataService.getDictionary(clubId, dicAlias)).subscribe(dic => {
      const res: any = dic;
      this.isLoadDicData.next(true);
      if (res && res.data) {
        this.data = JSON.parse(res.data);
      }
    });
  }
}
