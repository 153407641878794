import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';
import { PreloaderService } from './preloader.service';
import { QueryStringParams, ClubCityParams } from '../shared/models/parameters.model';
import { WidgetType, WidgetSettings, ScheduleWidgetSettings } from '../shared/models/widget.model';

@Injectable()
export class SettingsService {
    private _settings: BehaviorSubject<WidgetSettings> = new BehaviorSubject(null);
    private _params: BehaviorSubject<QueryStringParams> = new BehaviorSubject(null);

    constructor(
        private api: ApiService,
        private preloaderService: PreloaderService
    ) { }

    public get settings() {
        return this._settings.asObservable();
    }

    public get params() {
        return this._params.asObservable();
    }

    public loadDefaultSettings(club: ClubCityParams, widgetType: WidgetType) {
        const settings: WidgetSettings = {
            widgetId: null,
            clubId: club.clubId,
            widgetType,
            timeZone: null,
            settings: {
                style: null
            },
            dependenceWidgetId: null
        };

        this.api.cities.get(club.cityId)
            .subscribe(response => {
                settings.timeZone = response.result && response.result.timeZone;
                switch (widgetType) {
                    case WidgetType.Schedule: {
                        const scheduleSettings = settings as ScheduleWidgetSettings;
                        scheduleSettings.settings.showRoomFilter = false;
                        scheduleSettings.settings.showGroupFilter = true;
                        scheduleSettings.settings.showCoursesFilter = true;
                        scheduleSettings.settings.showCoachesFilter = true;
                        scheduleSettings.settings.showTrainingCategoryFilter = true;
                        scheduleSettings.settings.roomId = null;
                        scheduleSettings.settings.disableSubscribeOnClass = false;
                        scheduleSettings.settings.showComments = false;
                        break;
                    }
                }
                this._settings.next(settings);
            });
    }

    public loadRemoteSettings(params: QueryStringParams): Promise<WidgetSettings> {
        return new Promise(resolve => {
          this.preloaderService.increment();
          this._params.next(params);
          this.api.settings.getByWidgetId(params)
              .subscribe(response => {
                  this.api.setCulture(response.result.settings.culture);
                  this._settings.next(response.result);
                  this.preloaderService.decrement();
                  resolve(response.result);
              });
        })
    }
}
