import { Params } from '@angular/router';

export interface Wparams { w: string, clubId?: string | undefined; }
export interface VKparams { api_id: string; group_id: string; }
export interface AidParams { api_id: string; }
export interface ClubCityParams { clubId: string; cityId: string; }

export type QueryStringParams = Wparams | VKparams | AidParams;

export function isVkParams(params: QueryStringParams): params is VKparams {
    return (params as VKparams).api_id !== undefined && (params as VKparams).group_id !== undefined;
}

export function isWParams(params: QueryStringParams): params is Wparams {
    return (params as Wparams).w !== undefined;
}

export function isAidParams(params: QueryStringParams): params is AidParams {
    return (params as VKparams).api_id !== undefined && (params as VKparams).group_id === undefined;
}

export function isClubCityParams(params: Params): params is ClubCityParams {
    return (params as ClubCityParams).cityId !== undefined && (params as ClubCityParams).clubId !== undefined;
}
