import { InjectionToken, ClassProvider} from '@angular/core';
import { StaticProvider } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../services/modal.service';
import { SPErrorHandler, ModalReq } from '../shared/models/misc.models';

export const SP_ERROR_HANDLERS: InjectionToken<SPErrorHandler[]> = new InjectionToken('SP_ERROR_HANDLERS');

export class LogError implements SPErrorHandler {
    handle(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.log('Ошибка has proizoschla:', error.error.message);
        } else {
            console.log(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
    }
}

export class ShowErrorModal implements SPErrorHandler {
    constructor(
        private modalService: ModalService
    ) { }

    handle(error: HttpErrorResponse) {
        const msg: ModalReq = {
            title: 'Ошибка',
            message: (error.error && error.error.desc) || error.message
        };
        if (error.status === 0) {
            msg.title = 'Произошла сетевая ошибка. Проверьте, что подключение к интернету работает стабильно.';
            msg.message = null;
        }
        this.modalService.show(msg);
    }
}

export const SPErrorHandlerProviders: (ClassProvider | StaticProvider)[] = [
    { provide: SP_ERROR_HANDLERS, useClass: LogError, multi: true },
    { provide: SP_ERROR_HANDLERS, useClass: ShowErrorModal, deps: [ModalService], multi: true }
];
