import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, Input } from '@angular/core';
import { trigger,  transition, style, animate } from '@angular/animations';

import { ModalService } from '../../../services/modal.service';
import { ModalReq } from '../../models/misc.models';
import { HostMessageService } from '../../../services/host-message.service';
import { EventsService } from '../../../services/events.service';
import {Location} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {ProfileService} from '../../../services/profile.service';

const modalLeaveTimeout = 200;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0, paddingTop: '30px' }),
        animate('.2s ease-in', style({ opacity: 1, paddingTop: '0' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, paddingTop: '0' }),
        animate(`${modalLeaveTimeout}ms ease-in`, style({ opacity: 0, paddingTop: '50px' }))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit {
  public isApp = false;
  public isModalVisible: boolean;
  public modalData: ModalReq = null;
  public baseTime: number | undefined;
  public widgetId: string | undefined;
  public clubId: string | undefined;
  public wgScheduleFilterId: string | undefined;
  public filterName: string | undefined;
  public filterId: string | undefined;
  private subscription: Subscription;
  @ViewChild('container') elementView: ElementRef;
  @Input() bodyElement: HTMLBodyElement;

  constructor(
    private service: ModalService,
    private hostMessageService: HostMessageService,
    private _location: Location,
    private eventsService: EventsService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
  ) {
    this.subscription = route.queryParams.subscribe(
      (p: any) => {
        this.clubId = p['clubId'];
        this.baseTime = p['startTime'];
        this.widgetId = p['w'];
        this.wgScheduleFilterId = p['wgScheduleFilterId'];
        this.filterName = p['filterName'];
        this.filterId = p['filterId'];

      }
    );
    this.profileService.isApp.subscribe(m => this.isApp = m);
  }

  public onModalClick(event: Event, close) {
    // В окне не работаю radio, checkbox
    // Эта проверка решает этот баг
    const checkElement = <HTMLElement> event.target;
    if (checkElement.id.indexOf('Check') > 0 || checkElement.tagName == 'A' || checkElement.tagName == 'LABEL'){
      return;
    }
    if (close) {
      if (this.isApp)
        return setTimeout(() => parent.postMessage({ event: 'widget-modal-close' }, '*'), 0);
      this.service.close();
      window.history.back();
      /*if (this.wgScheduleFilterId) {
        this.router.navigate(['schedule'], { queryParams: {
          w: this.widgetId,
            clubId: this.clubId,
            baseTime: this.baseTime,
            wgScheduleFilterId: this.wgScheduleFilterId,
            filterName: this.filterName,
            filterId: this.filterId,
          } });
      } else {
        this.router.navigate(['schedule'], { queryParams: { w: this.widgetId, clubId: this.clubId, baseTime: this.baseTime } });
      }*/
    }

    setTimeout(() => {this.hostMessageService.postMessage({ type: 'widget-set-height', height: this.getScheduleHeight(), extra: true })}, 200);
    event.stopPropagation();

    return false;
  }
  public getScheduleHeight() {
    let body = document.querySelector('body');
    const settings = body.getBoundingClientRect();
    return settings.height;
  }


  public ngOnInit() {
    this.service.modalData
      .subscribe(modalData => {
        this.modalData = modalData;
        setTimeout(() => this.eventsService.publish('height'), 100);
        setTimeout(() => this.hostMessageService.postMessage({ type: 'widget-set-height', height: this.getScheduleHeight(), extra: true }), 200);
      });

    this.service.isModalVisible.subscribe(visible => {
      if (visible) {
        this.bodyElement.classList.add('modal-visible');
        this.hostMessageService.postMessage({ type: 'widget-modal-open' });
      } else {
        this.bodyElement.classList.remove('modal-visible');
        setTimeout(() => {
          this.hostMessageService.postMessage({ type: 'widget-modal-close' });
          this.hostMessageService.postMessage({ type: 'widget-set-height', height: this.getScheduleHeight(), extra: true });
        }, 100);
      }
    });
  }
}
