import { Component, ViewEncapsulation, } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';

import { PreloaderService } from '../../../services/preloader.service';
import * as Utility from '../../../shared/helpers/utility';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.2s ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.2s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PreloaderComponent {
  public Utility = Utility;
  public isBusy: boolean;
  constructor(
    public service: PreloaderService
  ) {
    this.service.isBusy.subscribe(isBusy => this.isBusy = isBusy);
  }
}
