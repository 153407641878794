import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { WidgetSettings } from '../shared/models/widget.model';


@Injectable()
export class FakeResponseInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.url.indexOf('common-getSettings') >= 0) {
            return next.handle(req).pipe(
                map(x => {
                    if (x instanceof HttpResponse && x.body.result) {
                        const newRes = x.clone();
                        if (req.body.id && !(newRes.body.result as WidgetSettings).widgetId) {
                            (newRes.body.result as WidgetSettings).widgetId = req.body.id;
                        }
                        return newRes;
                    }
                    return x;
                }));
        } else {
            return next.handle(req);
        }
    }
}
