import { Injectable } from '@angular/core';

export enum AppSettings {
  isEmptyScheduleDismissed = 'isEmptyScheduleDismissed',
  isEmptyPlacesDismissed = 'isEmptyPlacesDismissed',
  searchModel = 'searchModel2',
  userSubscriptions = 'userSubscriptions',
  userClubs = 'userClubs',
  userClasses = 'userClasses',
  userCards = 'userCards',
  userTheme = 'userTheme',
  userCart = 'userCart',
  userProfile = 'userProfile2',
  userInfo = 'userInfo2',
  instId = 'instId',
  token = 'Token'
}

@Injectable()
export class StorageService {

  public clear(key: string) {
    window.localStorage.removeItem(key);
  }

  public write(key: string, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public read(key: string) {
    const i = window.localStorage.getItem(key);
    return i ? JSON.parse(i) : null;
  }
}
