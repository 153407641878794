import { Pipe, PipeTransform } from '@angular/core';
import { DicService } from 'app/services/dic.service';

@Pipe({ name: 'dic' })
export class DicPipe implements PipeTransform {

  constructor(public dic: DicService) { }

  transform(value, alias) {
    if (!this.dic.hasOwnProperty("data")) {
      return value;
    }

    //Если несколько возможных вариантов, то к нему создаем вложенный объект словаря и обращаемся по ключу
    if(typeof this.dic.data[alias] === 'object')
    {
      return this.dic.data[alias][value] || value;
    }
    //Если есть перевод для продолжительности занятия, то редактируем продолжительность, возвращяемую duration.pipe
    else if(this.dic.data[alias] === 'durationEng')
    {
      return value.replace(/(ч)/g, 'h').replace(/(мин)/g, 'min');
    }
    else if(value && value !== '') {
      return this.dic.data[alias] || value;
    }
  }
}
