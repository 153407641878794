import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'schedule',
        loadChildren: () => import('app/modules/schedule/schedule.module').then(m => m.ScheduleModule)
    },
    {
        path: 'coaches',
        loadChildren: () => import('app/modules/coaches/coaches.module').then(m => m.CoachesModule)
    },
    {
        path: 'rooms',
        loadChildren: () => import('app/modules/rooms/rooms.module').then(m => m.RoomsModule)
    },
    {
        path: 'courses',
        loadChildren: () => import('app/modules/courses/courses.module').then(m => m.CoursesModule)
    },
    {
        path: 'subscriptions',
        loadChildren: () => import('app/modules/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
    },
    {
        path: 'payment',
        loadChildren: () => import('app/modules/payment/payment.module').then(m => m.PaymentModule)
    },
    {
        path: 'purchaseSubscription',
        loadChildren: () => import('app/modules/purchase-subscription/purchase-subscription.module').then(m => m.PurchaseSubscriptionModule)
    },
    {
      path: 'scheduleFilter',
      loadChildren: () => import('app/modules/schedule-filter/schedule-filter.module').then(m => m.ScheduleFilterModule)
    },
    {
      path: 'certificates',
      loadChildren: () => import('app/modules/certificates/certificates.module').then(m => m.CertificatesModule)
    },
    {
      path: 'rent',
      loadChildren: () => import('app/modules/rent/rent.module').then(m => m.RentModule)
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
