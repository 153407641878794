<div id="wg-modal" #container class="modal" (click)="onModalClick($event, true)" [@fade] *ngIf="modalData">
  <div class="modal-item" (click)="onModalClick($event, false)">
    <div class="close-button">
      <span class="cross" (click)="onModalClick($event, true)">
        <span class="cross__left"></span>
        <span class="cross__right"></span>
      </span>
    </div>

    <div class="modal-content">
      <ng-container *ngIf="modalData.templateRef">
        <ng-container *ngTemplateOutlet="modalData.templateRef; context: modalData"></ng-container>
      </ng-container>

      <ng-container *ngIf="!modalData.templateRef">
        <h3 class="modal-title" *ngIf="modalData.title">
          {{modalData.title}}
        </h3>

        <div class="row" *ngIf="modalData.message">
          <div class="col-xs-12">
            <div class="modal-value">
              {{modalData.message}}
            </div>
          </div>
        </div>
      </ng-container>

      <div class="col-xs-12 modal-button" (click)="onModalClick($event, true)">
        <div class="modal-wrap modal__wrap">
          <button class="modal-close form-link_item select-date-button class-request">{{'Закрыть' |dic: 'close'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
